import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

// Components
import Layout from '../../components/layout/Layout';
import ResourcesFilter from '../../components/filter/Resources';
import ResourceCard from '../../components/card/Resource';
import PaginationBlock from '../../components/block/Pagination';

// Resources Type
const ResourcesTypePage = ({ pageContext, data }) => {
  
  const {t} = useTranslation();

  const { type, languagePath } = pageContext;
  const { totalCount } = data.resources;
  
  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.resources'),
          item: languagePath + "resources/"
        },
        { 
          name: t('breadcrumb.types'),
          item: languagePath + "resources/types/"
        },
        { 
          name: t('resource.types.' + type + '.label')
        },
      ]
    }
  ];

  return (
    <Layout pageType="classic" 
      pageTitle={t('resource.types.' + type + '.meta.title', { title: data.site.siteMetadata.title, total_count: totalCount })} 
      pageDescription={t('resource.types.' + type + '.meta.description', { title: data.site.siteMetadata.title, total_count: totalCount })} 
      pageKeywords={t('resource.types.' + type + '.meta.keywords', { title: data.site.siteMetadata.title, total_count: totalCount })} 
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/resources/`}>{t('breadcrumb.resources')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/resources/types/`}>{t('breadcrumb.types')}</Link></li>
            <li className="breadcrumb-item active">{t('resource.types.' + type + '.label')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('resource.types.' + type + '.title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('resource.types.' + type + '.subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div className="row col-lg-divider">
          <div className="col-lg-3">
            <Link className="card-link ms-2" to="/resources/">
              <div className="card card-info-link card-sm d-grid mb-5 mb-md-3">
                <div className="card-body">
                  <span className="bi-chevron-left small ms-1"></span> {t('back')}
                </div>
              </div>
            </Link>
            <ResourcesFilter data={data} />
          </div>
          <div className="col-lg-9">
            <div className="ps-lg-2">
              <div className="row gx-3 mb-7">
                {data.resources.nodes &&
                  data.resources.nodes.map(resource => (
                    <ResourceCard key={resource.id} data={resource} cardStyle="1" cardClassName="col-sm-6 col-lg-6 mb-4"/>
                  ))
                }
              </div>
            </div>
            <div>
              <PaginationBlock 
                path={`resources/types/${slugify(type)}`} 
                currentPage={pageContext.currentPage} 
                numPages={pageContext.numPages} 
              />
            </div>            
          </div>
        </div>
      </div>
    </Layout>
  )
}

ResourcesTypePage.propTypes = {
  data: PropTypes.shape({
    types: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    tags: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default ResourcesTypePage

export const query = graphql`
  query ($type: String, $language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    resources: allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/data/resources/" },
        frontmatter: { type: { eq: $type }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          topics
          type
          description
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date_published(formatString: "D MMMM YYYY", locale: $language)
          date_modified(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    topics: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resources/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___topics) {
        title: fieldValue
        totalCount
      }
    }
    types: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/resources/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} } }
      )         
      {
      group(field: frontmatter___type) {
        title: fieldValue
        totalCount
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "resources", "topics", "types", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`